@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Archia;
  src: url(../src/assets/fonts/Archia_Regular/Archia\ Regular\ Webfont/archia-regular-webfont.ttf);
}

body {
  font-family: "Archia";
  background-color: black;
}
